.AutoplayTimer_wrapper_31ziUcO {
  width: 30%;
  height: 30%;
}

.AutoplayTimer_overlay_3fHZH5n {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}