.accountPopup_wrapper_1mYua5R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-left: solid thin #ddd !important;
  min-width: 272px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top :0
}

.accountPopup_wrapper_1mYua5R:hover {
  background-color: #f6f6f6;
}

.accountPopup_dropdown_inner_1WeRdey {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  list-style-type: none;
  border-bottom: 1px solid #ddd;
  border-top: none;
  padding: 10px 15px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #f5f6fa !important;
}

.accountPopup_wrapper_1mYua5R li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accountPopup_wrapper_1mYua5R li a span{
  margin-right: 8px;
}

.accountPopup_user_card_1WQgMxh {
  display: block;
}

.accountPopup_lead_text_3IPopV_,.accountPopup_sub_text_1ko_X3L {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.accountPopup_lead_text_3IPopV_ {
  font-size: 0.875rem;
  font-weight: 700;
  color: #364a63;
  display: block;
}

.accountPopup_lead_text_3IPopV_ + .accountPopup_sub_text_1ko_X3L {
  font-size: 12px;
}

.accountPopup_sub_text_1ko_X3L {
  display: block;
  font-size: 13px;
  color: #8094ae;
}

.accountPopup_toggle_zJPKLP1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.accountPopup_toggle_zJPKLP1 span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accountPopup_toggle_zJPKLP1 span svg{
  -ms-flex-item-align: center;
      align-self: center;
}

.accountPopup_SlideBtn_knob_1_xjVT_ {
  height: 1.3rem;
  width: 1.3rem;
  left: calc(2rem - 2px - 0.125rem);
  background-color: #dbdfea;
  position: absolute;
  content: "";
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 1.5rem;
  display: block;
  top: 50%;
}

.accountPopup_SlideBtn_NPrjDmV {
  border-radius: 4.6875rem;
  position: relative;
  width: 4rem !important;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  left: 0;
  height: 1.5rem;
  background-color: #fff;
  border: #dbdfea solid 2px;
  width: 3.5em !important;
}