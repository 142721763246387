.userItem_wrapper_3xtDvBS {
  padding: 15px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: solid thin #EEEEEE
}

.userItem_wrapper_3xtDvBS:hover {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.userItem_wrapper_3xtDvBS:hover .userItem_actions_LRydA7s {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.userItem_wrapper_3xtDvBS .userItem_adminLabel_2jFJBzN {
  margin-left: 10px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f6f6f6;
  font-size: 12px;
  border: solid thin #ddd;
}

.userItem_actions_LRydA7s {
  margin-left: auto;
  /* opacity: 0; */
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.userItem_actions_LRydA7s .userItem_button_2EQ9fmk {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px
}

.userItem_actions_LRydA7s .userItem_button_2EQ9fmk:hover svg {
  fill: #2331A8;
}