.manageUsers_tabHeader_Dm16RgF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.manageUsers_tabHeader_Dm16RgF .manageUsers_tabTitle_1vj8aWl {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}

.manageUsers_form_35Su0W9 {
  padding: 0 20px
}

.manageUsers_form_35Su0W9 .manageUsers_formGroup_2owYIN6 {
  margin-bottom: 15px;
}

.manageUsers_form_35Su0W9 label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.manageUsers_form_35Su0W9 .manageUsers_input_1xrU-TU {
  width: 100%;
}

.manageUsers_form_35Su0W9 input[type=checkbox] {
  margin-right: 10px;
  height: 13px;
}

.manageUsers_adminInfo_Qg9gLJQ {
  font-size: 12px;
  color: #888;
}