.sites_tabHeader_38mhSeo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.sites_tabHeader_38mhSeo .sites_tabTitle_beE2h13 {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}

.sites_site_2pEQi9N {
  padding: 15px 10px;
  border-bottom: solid thin #EEEEEE;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.sites_site_2pEQi9N:hover .sites_actions_xoiilNn button {
  display: unset;
}

.sites_site_2pEQi9N .sites_actions_xoiilNn {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end
}

.sites_site_2pEQi9N .sites_actions_xoiilNn > * {
  margin-left: 15px;
}

.sites_site_2pEQi9N .sites_actions_xoiilNn button:disabled {
  opacity: 0.3;
}

.sites_label_1OQEvVC {
  margin-left: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  background-color: #f6f6f6;
  font-size: 12px;
  border: solid thin #ddd;
}