.player_playerBody_2JH3OUN {
  background: #fff;
  /* border-radius: 3px; */
  /* padding: 10px 10px 5px 10px; */
  /* box-shadow: 0px 2px 10px 0 $gray-light; */
  height: 100%;
  /* border: solid thin $gray-light; */
  border-right: solid thin #ddd;
}

.player_screenWrapper_3yfit-n {
  width: 100%;
  position: relative;
  height: 100%;
  /* border: solid thin $gray-light; */
  /* border-radius: 3px; */
  overflow: hidden;
  background: #f6f6f6;
}

.player_disconnected_300pLqr {
  font-size: 40px;
  font-weight: 200;
  color: #888;
}

.player_overlay_1ULgyXj {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
  /*   &[data-protect] {
  pointer-events: none;
  background: $white;
  opacity: 0.3;
}
 */
}

.player_overlay_1ULgyXj .player_iconWrapper_1oMky_G {
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  /* Animation */
}

.player_overlay_1ULgyXj .player_zoomIcon_1oqGrFB {
  opacity: 1;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  -webkit-transition: all .8s;
  transition: all .8s;
}

.player_overlay_1ULgyXj .player_playIcon_160vwf2 {
  width: 30px;
  height: 30px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-play' viewBox='0 0 16 16' fill='%23888'%3E %3Cpath d='M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.player_overlay_1ULgyXj .player_pauseIcon_2ItUQqJ {
  width: 30px;
  height: 30px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-pause' viewBox='0 0 16 16' fill='%23888'%3E %3Cpath d='M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.player_playerView_1tJJ_XD {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.player_inspectorMode_1vK29cp {
  z-index: 99991 !important;
}

.player_liveStatusText_3pBLxkx {
  color: #ddd;
  font-size: 40px;
}