#app {
  padding: 0;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

* {
  border-color: #EEEEEE;
}

.page {
  padding-top: 50px;
}

.page-margin {
  padding-top: 81px;
}

.container-fit {
  margin: 0 30px 0px;
}

.container {
  margin: 0 30px 30px;
}

@media only screen and (max-width: 1380px) {
  .container-70 {
    width: 90%;
  }
}

@media only screen and (min-width: 1380px) {
  .container-70 {
    width: 1280px;
  }
}

.container-70 {
  position: relative;
  margin: 0 auto;
}

.container-90 {
  width: 98%;
  margin: 0 auto;
}

.side-menu {
  width: 250px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding-right: 20px;
  position: fixed;
  top: 81px
}

.side-menu::-webkit-scrollbar {
  width: 0px;
}

.side-menu:hover::-webkit-scrollbar {
  width: 0px;
}

.side-menu-margined {
  margin-left: 260px;
}

.feedbackPopup {
  position: fixed;
  right: -2px;
  top: 50%;
  -webkit-transform: rotate(-90deg) translate(50%, -50%);
          transform: rotate(-90deg) translate(50%, -50%);
  -webkit-transform-origin: right;
          transform-origin: right;
  z-index: 1;
  color: #fff;
  background-color: #394eff;
  border-color: #394eff;
  border-radius: 5px;
}

.feedbackPopup button span {
  color: white !important;
}

.feedbackPopup button:hover span{
  color: #394eff !important;
}

.top-header {
  margin-bottom: 25px;
  /* border: dashed thin gray; */
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.page-title {
  font-size: 22px;
  margin-right: 15px
}

.page-title > span {
  font-weight: 300;
}

.page-title .title {
  margin-right: 15px;
}

.page-title .title span {
  color: #888;
  font-weight: 300;
}

.page-title-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

[data-hidden=true] {
  display: none !important;
}

[data-disabled=true] {
  pointer-events: none;
  opacity: .5;
}


