.screen_screen_2DIadKj {
  overflow: hidden;
  position: absolute;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
          box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}

.screen_iframe_3RJq3u5 {
  position: absolute;
  border: none;
  background: whilte;
}

.screen_overlay_1Khj9T7 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}