.feedbackPopup_popup_2WA4jVe {
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
  left: -10px !important;
}

.feedbackPopup_wrapper_2b8xfXU {
  background-color: white;
  width: 350px;
}

.feedbackPopup_header_1hvYsdG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
}

.feedbackPopup_title_3s6b4ZB {
  margin-right: auto;
  font-weight: bold;
}

.feedbackPopup_body_1PmBkeH textarea {
  border: solid thin #ddd;
  border-radius: 3px;
  resize: none;
}

.feedbackPopup_closeBtn_34x8g62 {
  cursor: pointer;
  position: absolute;
  background: #ffffff;
  width: 27px;
  border-radius: 35px;
  height: 27px;
  -webkit-box-shadow: 1px -2px 4px 0 rgba(34,36,38,.12), 3px -1px 10px 0 rgba(34,36,38,.15);
          box-shadow: 1px -2px 4px 0 rgba(34,36,38,.12), 3px -1px 10px 0 rgba(34,36,38,.15);
  right: -4px;
  top: -13px;
  z-index: 111
}

.feedbackPopup_closeBtn_34x8g62:hover {
  background-color: #F6F7FF;
  border-color: #D0D4F2;
}

.feedbackPopup_closeBtn_34x8g62:after, .feedbackPopup_closeBtn_34x8g62:before {
  content: "";
  height: 13px;
  width: 13px;
  border-top: 3px solid #d9d9d9;
  position: absolute;
  top: 10px;
  right: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.feedbackPopup_closeBtn_34x8g62:before {
  color: white;
  right: 11px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.feedbackPopup_footer_3EktBjA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}

.feedbackPopup_footer_3EktBjA button img{
  margin-right: 10px;
}

.feedbackPopup_wrapper_2b8xfXU img{
  width: 20px;
  height: 20px;
}

.feedbackPopup_emojis_dil-_cc{
  width: 100%;
  max-width: 256px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: auto;
}

.feedbackPopup_emojis_btn_2UxTzdG {
  padding: 20px;
  position: relative;
}

.feedbackPopup_emojis_btn_2UxTzdG button{
  position: absolute;
  top: calc(50% + 2px);
  cursor: pointer;
  font-size: 30px;
  outline: 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: 0px center;
  -webkit-transform: translateZ(0px) translateY(-50%) translateX(-50%);
          transform: translateZ(0px) translateY(-50%) translateX(-50%);
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.18, 1.3) 0s;
  -webkit-transform-origin: 0px 0px;
          transform-origin: 0px 0px;
  font-family: "Apple Color Emoji", sans-serif;
  opacity: 0.8;
  -webkit-filter: grayscale(0.8);
          filter: grayscale(0.8);
}

.feedbackPopup_emojis_btn_2UxTzdG button:hover {
  opacity: 1 !important;
  -webkit-filter: none !important;
          filter: none !important;
  -webkit-transform: scale(1.5) translateY(-50%) translateX(-50%) !important;
          transform: scale(1.5) translateY(-50%) translateX(-50%) !important;
}