.sessionList_customMessage_1sSZAUS {
  padding: 5px 10px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-size: 12px !important;
  color: #888 !important;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.sessionList_customMessage_1sSZAUS > div {
  -webkit-box-flex: 0 !important;
      -ms-flex: none !important;
          flex: none !important;
}