.timeTable_timeCell_206uMcP {
  border-left: solid thin rgba(0, 0, 0, 0.05);
}

.timeTable_headers_37UwOT9 {
  -webkit-box-shadow: 0 1px 2px 0 #ddd;
          box-shadow: 0 1px 2px 0 #ddd;
  background-color: #f6f6f6;
  color: #888;
  font-size: 12px;
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}

.timeTable_infoHeaders_1fUiE_x {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.timeTable_infoHeaders_1fUiE_x .timeTable_headerCell_2TlmfDk {
  padding: 4px 2px;
}

.timeTable_waterfallHeaders_3yazw1H {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1
}

.timeTable_waterfallHeaders_3yazw1H .timeTable_timeCell_206uMcP {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  padding: 4px 0;
}

.timeTable_list_3__jyXj {
  /* TODO hide the scrollbar track */
}

.timeTable_list_3__jyXj::-webkit-scrollbar {
  width: 1px;
}

.timeTable_list_3__jyXj {
  scrollbar-width: thin;
  font-size: 12px;
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
}

.timeTable_row_3eBFhsw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  /*align-items: center;
  cursor: pointer;
  */
  /* &:nth-child(even) {
    background-color: $gray-lightest;
  } */
  /* & > div:first-child {
    padding-left: 5px;
  }*/
}

.timeTable_cell_1N4EF86 {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  padding: 0 2px;
}

.timeTable_hoverable_3lLaOEz {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer
}

.timeTable_hoverable_3lLaOEz:hover {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #666;
}

.timeTable_timeBarWrapper_3uw0W7F{
  overflow: hidden;
}

.timeTable_timePart_3X-bJ_i {
  position: absolute;
  top: 0;
  bottom: 0;
  /*left:0;*/
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px
}

.timeTable_timePart_3X-bJ_i .timeTable_timeCell_206uMcP {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  z-index: 1;
  pointer-events: none;
}

.timeTable_timePart_3X-bJ_i .timeTable_refLine_1Bf0yQX {
  position: absolute;
  height: 100%;
  width: 1px;
  z-index: 1;
}

.timeTable_activeRow_1XwdJdK {
  background-color: #394EFF;
}