.eventFilter_searchField_2MpErPW {
  -webkit-box-shadow: none !important;
          box-shadow: none !important
}

.eventFilter_searchField_2MpErPW input {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 3 !important;
  border: solid thin #ddd !important;
  height: 46px !important;
  font-size: 16px;
}

.eventFilter_wrapper_36KAcG1 {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  position: relative
}

.eventFilter_wrapper_36KAcG1 .eventFilter_clearStepsButton_3s2FFZE {
  position: absolute;
  bottom: 10px;
  right: 10x;
}

.eventFilter_randomElement_2sTvNrc {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 8;
  padding: 15px;
  padding-left: 40px;
}

.eventFilter_dropdownMenu_36NviXp {
  max-width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.eventFilter_dropdownMenu_36NviXp[data-hidden=true] {
  display: none !important;
}

.eventFilter_header_26Lxdse {
  padding: 5px 10px;
  letter-spacing: 1.5px;
  background-color: #f6f6f6;
  color: #888;
  font-size: 12px;
  text-transform: uppercase;
}

.eventFilter_dateRange_1Ntigve {
  color: red;
  z-index: 8;
  position: absolute;
  right: 9px;
  top: 9px;
}