.playerBlockHeader_header_1d_u7IO {
  height: 50px;
  border-bottom: solid thin #ddd;
  padding: 10px 15px;
  background-color: white;
}

.playerBlockHeader_divider_3cW-h6V {
  width: 1px;
  height: 100%;
  margin: 0 15px;
  background-color: #ddd;
}

