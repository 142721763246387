.errorName_function_3EZrZ3- {
  max-width: 100% !important;
  color: #888;
  font-weight: 300;
}

.errorName_truncate_2QFBOBT{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}