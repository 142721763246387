.timeline_progress_2hBns0d {
  height: 10px;
  border-radius: 1px;
  background: transparent;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.timeline_skipInterval_3HkY0Jh {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(207 186 186);
  pointer-events: none;
}

.timeline_event_2hz-4Pd {
  position: absolute;
  width: 8px;
  height: 8px;
  border: solid 1px white;
  margin-left: -4px;
  border-radius: 50%;
  background: rgba(136, 136, 136, 0.8);
  pointer-events: none;
  /* top: 0; */
  /* bottom: 0; */
  /*  &:hover {
    width: 10px;
    height: 10px;
    margin-left: -6px;
    z-index: 1;
  };*/
}

/* .event.click, .event.input {
  background: $green;
}
.event.location {
  background: $blue;
} */

.timeline_markup_2KFW2S5 {
  position: absolute;
  width: 2px;
  height: 8px;
  border-radius: 2px;
  margin-left: -15px
}

.timeline_markup_2KFW2S5:hover {
  z-index: 9999;
}

/* .markup.log {
  background: $blue;
}

.markup.error {
  background: $red;
}

.markup.warning {
  background: $orange;
} */

.timeline_markup_2KFW2S5.timeline_info_39zct83 {
  background: #0076FF;
}

.timeline_popup_3slWytJ {
  max-width: 300px !important;
  /* max-height: 300px !important; */
  overflow: hidden;
  text-overflow: ellipsis
}

.timeline_popup_3slWytJ span {
  display: block;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timeline_timeline_3WeeHez {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.timeline_clickRage_1_mYN3n {
  position: absolute;
  width: 2px;
  height: 8px;
  border-radius: 2px;
  margin-left: -1px;
  /* background: $red; */
}

.timeline_returningLocation_3WEvY81 {
  position: absolute;
  height: 20%;
  border-radius: 50%;
  /* background: $red; */
  width: 12px;
}

.timeline_feedbackIcon_3ROgbtI {
  position: absolute;
  margin-top: -20px;
  margin-left: -9px;
  background-color: #f6f6f6;
  padding: 2px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
          box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1)
}

.timeline_feedbackIcon_3ROgbtI .timeline_tooltipArrow_38IAJkt {
  width: 50px;
  height: 25px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  overflow: hidden;
}

.timeline_feedbackIcon_3ROgbtI .timeline_tooltipArrow_38IAJkt::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #f6f6f6;
  -webkit-transform: translateX(-50%) translateY(50%) rotate(45deg);
          transform: translateX(-50%) translateY(50%) rotate(45deg);
  bottom: 100%;
  left: 50%;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
          box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
}