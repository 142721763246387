.listItem_wrapper_QwQCbHn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 10px;
  border-bottom: solid thin #ddd;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer
}

.listItem_wrapper_QwQCbHn:hover {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.listItem_wrapper_QwQCbHn:hover .listItem_actions_1ilPGKT {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.listItem_preDefined_3uC7gjm {
  cursor: not-allowed;
}

.listItem_actions_1ilPGKT {
  margin-left: auto;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.listItem_actions_1ilPGKT .listItem_button_2VkDekQ {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px
}

.listItem_actions_1ilPGKT .listItem_button_2VkDekQ:hover svg {
  fill: #2331A8;
}

.listItem_tag_yX_w2Mk {
  margin-left: 10px;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #f6f6f6;
  -webkit-box-shadow: 0 0 0 1px #ddd inset;
          box-shadow: 0 0 0 1px #ddd inset;
}