.sharePopup_popup_1vvkFwt {
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
  left: -10px !important;
}

.sharePopup_wrapper_RET9z1J {
  background-color: white;
  width: 350px;
}

.sharePopup_header_D1QVb6J {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
}

.sharePopup_title_1ULTmPg {
  margin-right: auto;
  font-weight: bold;
}

.sharePopup_closeButton_3uGWVvc {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x' viewBox='0 0 16 16' fill='%23ddd'%3E %3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sharePopup_inputField_3KK2x6A {
  display: block !important
}

.sharePopup_inputField_3KK2x6A label {
  font-weight: 300 !important;
}

.sharePopup_inputField_3KK2x6A input {
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  width: 100%;
}

.sharePopup_link_blBW4_S{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sharePopup_link_blBW4_S .sharePopup_shareButton_2YLXl_4{
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 1em;
}

.sharePopup_body_2-88e39 textarea {
  border: solid thin #ddd;
  border-radius: 3px;
  resize: none;
}

.sharePopup_durationselect_2Esa4Cq{
  width:205px;
}

.sharePopup_footer_1vThepo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}

.sharePopup_footer_1vThepo button img{
  margin-right: 10px;
}

.sharePopup_wrapper_RET9z1J img{
  width: 20px;
  height: 20px;
}

.sharePopup_form_MKZ43lH img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

textarea {
  width: 100%;
  background-color: #f6f6f6;
  border: none;
  padding: 10px;
}

.sharePopup_shreButtons_1a9UiLr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: auto;
  padding: 0 10px;
}

.sharePopup_icon_2_r0XlZ {
  margin: 0 8px
}

.sharePopup_icon_2_r0XlZ:first-child {
  margin-left: 0;
}

.sharePopup_slack_bEtxu0r {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-labelledby='simpleicons-slack-icon' role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23394EFF'%3E%3Ctitle id='simpleicons-slack-icon'%3ESlack icon%3C/title%3E%3Cpath d='M9.879 10.995l1.035 3.085 3.205-1.074-1.035-3.074-3.205 1.08v-.017z'/%3E%3Cpath d='M18.824 14.055l-1.555.521.54 1.61c.218.65-.135 1.355-.786 1.574-.15.045-.285.067-.435.063-.511-.015-.976-.338-1.155-.849l-.54-1.607-3.21 1.073.539 1.608c.211.652-.135 1.358-.794 1.575-.15.048-.285.067-.435.064-.51-.015-.976-.34-1.156-.85l-.539-1.619-1.561.524c-.15.045-.285.061-.435.061-.511-.016-.976-.345-1.155-.855-.225-.66.135-1.364.78-1.575l1.56-.525L7.5 11.76l-1.551.525c-.141.048-.285.066-.428.064-.495-.016-.975-.338-1.141-.848-.209-.65.135-1.354.796-1.574l1.56-.52-.54-1.605c-.21-.654.136-1.359.796-1.575.659-.22 1.363.136 1.574.783l.539 1.608L12.3 7.544l-.54-1.605c-.209-.645.135-1.35.789-1.574.652-.211 1.359.135 1.575.791l.54 1.621 1.555-.51c.651-.219 1.356.135 1.575.779.218.654-.135 1.359-.784 1.575l-1.557.524 1.035 3.086 1.551-.516c.652-.211 1.358.135 1.575.795.22.66-.135 1.365-.779 1.574l-.011-.029zm4.171-5.356C20.52.456 16.946-1.471 8.699 1.005.456 3.479-1.471 7.051 1.005 15.301c2.475 8.245 6.046 10.17 14.296 7.694 8.245-2.475 10.17-6.046 7.694-14.296z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.sharePopup_trello_3OVpWTM {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-labelledby='simpleicons-trello-icon' role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23394EFF'%3E%3Ctitle id='simpleicons-trello-icon'%3ETrello icon%3C/title%3E%3Cpath d='M21 0H3C1.343 0 0 1.343 0 3v18c0 1.656 1.343 3 3 3h18c1.656 0 3-1.344 3-3V3c0-1.657-1.344-3-3-3zM10.44 18.18c0 .795-.645 1.44-1.44 1.44H4.56c-.795 0-1.44-.646-1.44-1.44V4.56c0-.795.645-1.44 1.44-1.44H9c.795 0 1.44.645 1.44 1.44v13.62zm10.44-6c0 .794-.645 1.44-1.44 1.44H15c-.795 0-1.44-.646-1.44-1.44V4.56c0-.795.646-1.44 1.44-1.44h4.44c.795 0 1.44.645 1.44 1.44v7.62z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.sharePopup_shareButton_2YLXl_4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
  border: solid thin transparent;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #394EFF
}

.sharePopup_shareButton_2YLXl_4:hover {
  background-color: #F6F7FF;
  border-color: #D0D4F2;
}

.sharePopup_folder_pxVkCVh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -1em -1em 1.5em -1em;
  background: #f6f6f6;
}

.sharePopup_bg_z2_XZ7n {
  background: white;
}

.sharePopup_folder_pxVkCVh .sharePopup_shareButton_2YLXl_4 {
  width: 6.2em;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  padding: 12px 30px;
  border: none
}

.sharePopup_folder_pxVkCVh .sharePopup_shareButton_2YLXl_4:hover {
  background-color: white;
  border: none;
}

.sharePopup_noData_xWGBbtA{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #929293;
  margin: 3em 0 2em 0em;
}

.sharePopup_closeBtn_mUoKSQx {
  cursor: pointer;
  position: absolute;
  background: #ffffff;
  width: 27px;
  border-radius: 35px;
  height: 27px;
  -webkit-box-shadow: 1px -2px 4px 0 rgba(34,36,38,.12), 3px -1px 10px 0 rgba(34,36,38,.15);
          box-shadow: 1px -2px 4px 0 rgba(34,36,38,.12), 3px -1px 10px 0 rgba(34,36,38,.15);
  right: -4px;
  top: -13px;
  z-index: 111
}

.sharePopup_closeBtn_mUoKSQx:hover {
  background-color: #F6F7FF;
  border-color: #D0D4F2;
}

.sharePopup_closeBtn_mUoKSQx:after, .sharePopup_closeBtn_mUoKSQx:before {
  content: "";
  height: 13px;
  width: 13px;
  border-top: 3px solid #d9d9d9;
  position: absolute;
  top: 10px;
  right: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sharePopup_closeBtn_mUoKSQx:before {
  color: white;
  right: 11px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sharePopup_addBtn_3GLBZTR{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: -1.5em;
  margin-right: -1em;
  margin-bottom: 1.5em;
}

.sharePopup_addBtn_3GLBZTR input {
  margin: 0 !important;
  border-radius: 0px 0px 0px 5px !important;
}

.sharePopup_addBtn_3GLBZTR .sharePopup_shareButton_2YLXl_4{
  padding: 6px 13px;
  border-radius: 0px 5px 5px 0px;
  background: #f6f6f6;
  font-size: x-large;
  border-top: 1px solid #e5e5e5;
}

.sharePopup_required_3qoM7J8 {
  position: absolute;
  color: #88888896;
  top: 6.6em;
  font-size: smaller;
  right: 1.2em;
}

.sharePopup_addMail_tis-jyt {
  position: absolute;
  right: 15px;
  top: 21.4em;
  padding: 6px;
  background: #eeeeee;
  color: #394eff;
}

.sharePopup_addMail_tis-jyt:hover{
}

.sharePopup_error_2g5N37e {
  margin-top: -1em;
  color: #d68381;
}

.sharePopup_remove_1k-fWtQ .sharePopup_remove_span_dZyp6ZG{
  padding-left: 6px;
  font-size: smaller;
  color: #b62727;
}

.sharePopup_remove_1k-fWtQ {
  background-color: rgb(238 238 238);
  padding: 6px 20px;
  color: #394eff;
  cursor: pointer;
  border-radius: 25px;
  border: none;
}

.sharePopup_remove_1k-fWtQ:hover {
  background-color: #b62727;
  color: white ;
}

.sharePopup_remove_1k-fWtQ:hover .sharePopup_remove_span_dZyp6ZG{
  color: white;
}