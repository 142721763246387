/* Auto-generated, DO NOT EDIT */

/* fill */

.fill-main { fill: #394EFF
}

.fill-gray-light-shade { fill: #EEEEEE
}

.fill-gray-lightest { fill: #f6f6f6
}

.fill-gray-light { fill: #ddd
}

.fill-gray-medium { fill: #888
}

.fill-gray-dark { fill: #666
}

.fill-gray-darkest { fill: #333
}

.fill-gray-darkmode { fill: #101924
}

.fill-gray-darkmodetext { fill: #8094ae
}

.fill-gray-darkmodborder { fill: #203247
}

.fill-teal { fill: #394EFF
}

.fill-teal-dark { fill: #2331A8
}

.fill-teal-light { fill: #D0D4F2
}

.fill-tealx { fill: #3EAAAF
}

.fill-tealx-light { fill: #E2F0EE
}

.fill-tealx-light-border { fill: #C6DCDA
}

.fill-orange { fill: #E28940
}

.fill-yellow { fill: #FFFBE5
}

.fill-yellow2 { fill: #F5A623
}

.fill-orange-dark { fill: #C26822
}

.fill-green { fill: #42AE5E
}

.fill-green2 { fill: #00dc69
}

.fill-green-dark { fill: #2C9848
}

.fill-red { fill: #cc0000
}

.fill-red2 { fill: #F5A623
}

.fill-blue { fill: #366CD9
}

.fill-blue2 { fill: #0076FF
}

.fill-active-blue { fill: #F6F7FF
}

.fill-active-blue-border { fill: #D0D4F2
}

.fill-pink { fill: #ffb9b9
}

.fill-white { fill: #fff
}

.fill-borderColor { fill: [object Object]
}

/* color */

.color-main { color: #394EFF
}

.color-gray-light-shade { color: #EEEEEE
}

.color-gray-lightest { color: #f6f6f6
}

.color-gray-light { color: #ddd
}

.color-gray-medium { color: #888
}

.color-gray-dark { color: #666
}

.color-gray-darkest { color: #333
}

.color-gray-darkmode { color: #101924
}

.color-gray-darkmodetext { color: #8094ae
}

.color-gray-darkmodborder { color: #203247
}

.color-teal { color: #394EFF
}

.color-teal-dark { color: #2331A8
}

.color-teal-light { color: #D0D4F2
}

.color-tealx { color: #3EAAAF
}

.color-tealx-light { color: #E2F0EE
}

.color-tealx-light-border { color: #C6DCDA
}

.color-orange { color: #E28940
}

.color-yellow { color: #FFFBE5
}

.color-yellow2 { color: #F5A623
}

.color-orange-dark { color: #C26822
}

.color-green { color: #42AE5E
}

.color-green2 { color: #00dc69
}

.color-green-dark { color: #2C9848
}

.color-red { color: #cc0000
}

.color-red2 { color: #F5A623
}

.color-blue { color: #366CD9
}

.color-blue2 { color: #0076FF
}

.color-active-blue { color: #F6F7FF
}

.color-active-blue-border { color: #D0D4F2
}

.color-pink { color: #ffb9b9
}

.color-white { color: #fff
}

.color-borderColor { color: [object Object]
}

/* color */

.hover-main:hover { color: #394EFF
}

.hover-gray-light-shade:hover { color: #EEEEEE
}

.hover-gray-lightest:hover { color: #f6f6f6
}

.hover-gray-light:hover { color: #ddd
}

.hover-gray-medium:hover { color: #888
}

.hover-gray-dark:hover { color: #666
}

.hover-gray-darkest:hover { color: #333
}

.hover-gray-darkmode:hover { color: #101924
}

.hover-gray-darkmodetext:hover { color: #8094ae
}

.hover-gray-darkmodborder:hover { color: #203247
}

.hover-teal:hover { color: #394EFF
}

.hover-teal-dark:hover { color: #2331A8
}

.hover-teal-light:hover { color: #D0D4F2
}

.hover-tealx:hover { color: #3EAAAF
}

.hover-tealx-light:hover { color: #E2F0EE
}

.hover-tealx-light-border:hover { color: #C6DCDA
}

.hover-orange:hover { color: #E28940
}

.hover-yellow:hover { color: #FFFBE5
}

.hover-yellow2:hover { color: #F5A623
}

.hover-orange-dark:hover { color: #C26822
}

.hover-green:hover { color: #42AE5E
}

.hover-green2:hover { color: #00dc69
}

.hover-green-dark:hover { color: #2C9848
}

.hover-red:hover { color: #cc0000
}

.hover-red2:hover { color: #F5A623
}

.hover-blue:hover { color: #366CD9
}

.hover-blue2:hover { color: #0076FF
}

.hover-active-blue:hover { color: #F6F7FF
}

.hover-active-blue-border:hover { color: #D0D4F2
}

.hover-pink:hover { color: #ffb9b9
}

.hover-white:hover { color: #fff
}

.hover-borderColor:hover { color: [object Object]
}
