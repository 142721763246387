.ui.dropdown .menu {
  z-index: 1900;
}

.ui.dropdown .menu>.item {
  color: #454545;
}

.dark_mode .ui.dropdown .menu>.item:hover,
.dark_mode .ui.dropdown .menu {
  background: #18212d;
}

.dark_mode .ui.dropdown .menu>.item:hover {
  background-color: #101924 !important;
}

.customDropdownSmall {
  min-width: 70px !important;
}

.customDropdown {
  min-height: 0 !important;
  height: 30px !important;
  padding: 0 !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  padding: 0 8px !important;
  font-weight: normal !important;
}

.ui.selection.active.customDropdown .menu {
  border-color: #394EFF !important;
}

.ui.search.customDropdown>input.search,
.ui.search.customDropdown.active>input.search, 
.ui.search.customDropdown.visible>input.search {
  padding: 6px !important;
}

.ui.search.customDropdown>.text {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui.selection.customDropdown>.dropdown.icon {
  top: 5px !important;
}

.ui.selection.customDropdown>.search.icon {
  padding: 6px !important;
}

.customLightDropdown {
  min-height: 0 !important;
  height: 37px !important;
  padding: 0 !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  padding: 0 8px !important;
  font-weight: normal !important;
  color: #666 !important;
  border: solid thin #ddd !important;
}

.ui.selection.active.customLightDropdown .menu {
  border-color: #394EFF !important;
}

.ui.selection.customLightDropdown>.dropdown.icon {
  top: 8px !important;
}

.ui.selection.customLightDropdown:focus {
  border-color: #394EFF !important;
}

.ui.modal>.header {
  border-bottom: none !important;
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 1) !important;
          box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 1) !important;
}

.ui.modal>.content {
  background-color: #f6f6f6;
}

.ui.modal>.actions {
  background-color: #FFF !important;
  border-top: none !important;
  text-align: left !important;
}

.ui.input input {
  padding: 8.5px !important;
}

.ui.form .field {
  margin-bottom: 25px;
}

.ui.form .field>label {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.ui.searchDropdown .menu>.item {
  padding: 0 !important;
  border-bottom: solid thin #f6f6f6 !important;
}

.ui.searchDropdown .ui.dropdown {
  height: 46px !important;
}

.ui.searchDropdown .ui.input {
  width: 100% !important;
  height: 46px !important;
  font-size: 16px !important;
}

.ui.searchDropdown .ui.input input {
  border: none !important;
  border-radius: 0;
}

.ui.searchDropdown.small .ui.input {
  height: 36px !important;
}

.ui.searchDropdown .menu {
  width: 100%;
}

.ui.searchDropdown .menu .header {
  color: #888;
}

.ui.searchDropdown .menu > .item {
  padding: 0 !important;
  color: #666;
}

.ui.searchDropdown .menu > .item:hover {
  background-color: #F6F7FF !important;
}

.ui.customCheckbox {
  z-index: 1;
}

.ui.customCheckbox label {
  color: #333;
  font-weight: 400;
}

.ui.customCheckbox label:after {
  font-size: 12px !important;
  color: #394EFF !important;
}

.ui.radio.customCheckbox input:checked~label:after {
  background-color: #394EFF !important;
}

.ui.toggle.customCheckbox label:before {
  width: 44px;
  height: 27px;
}

.ui.toggle.customCheckbox input~label:after {
  top: 1px;
  left: 1px;
  width: 25px;
  height: 25px;
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-check' viewBox='0 0 16 16' fill='%23ddd'%3E %3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-size: 15px 15px !important;
  padding: 5px;
}

.ui.toggle.customCheckbox input:checked~label:after {
  left: 18px;
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-check' viewBox='0 0 16 16' fill='%233EAAAF'%3E %3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-size: 15px 15px !important;
}

.ui.toggle.checkbox input:checked~.box:before, 
.ui.toggle.checkbox.customCheckbox input:checked~label:before {
  background-color: #3EAAAF !important;
}

.customInput {
  height: 29px
}

.customInput .ui.label {
  font-weight: 300 !important;
  padding: 7px 10px !important;
}

.ui.input input,
.ui.button {
  font-family: 'Roboto', 'ArialMT', 'Arial' !important;
}

.ui.small.button {
  height: 32px !important;
  padding: 5px 10px !important;
}

.ui.smallest.button {
  height: 26px !important;
  padding: 3px 10px !important;
  font-size: 12px !important;
}

.ui.button:disabled {
  cursor: not-allowed !important;
}

.ui.button.copy-button {
  background-color: #ffff !important;
  -webkit-box-shadow: inset 0px 0px 0px 1px #ddd;
          box-shadow: inset 0px 0px 0px 1px #ddd;
  font-weight: 400;
  text-transform: uppercase;
}

.ui.selection.dropdown>.search.icon {
  padding: 10px;
}

.input-small {
  height: 30px !important;
}

.customPopupText {
  font-family: 'menlo', 'monaco', 'consolas', monospace !important;
  letter-spacing: -.04em;
  font-size: .9rem;
}

.ui.styled.accordion .title {
  color: #666;
}

.filterDropdown {
  padding: 6px !important;
  min-height: 28px !important;
  font-size: 13px !important
}

.filterDropdown.ui.search.selection.dropdown >input.search {
  padding: 5px !important;
  color: #888 !important;
  padding-right: 30px !important;
  text-overflow: ellipsis;
}

.filterDropdown .ui.label {
  background-color: transparent !important;
  font-weight: 300;
  font-size: 12px;
  padding: 4px 12px !important;
}

.filterDropdown i,
  .filterDropdown .search.icon {
  color: #888 !important;
  top: 3px !important;
  right: 8px !important;
}

.filterDropdown i,

  .filterDropdown .delete.icon {
  padding: 0 !important;
}

.ui.multiple.search.dropdown>.text {
  padding: 5px !important;
  margin: 0 !important;
  font-size: 13px !important;
}

.ui.multiple.search.dropdown> input {
  padding: 0 !important;
}

.ui.dropdown:not(.button) > .default.text {
  color: #888;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: #394EFF !important;
}

.ui.toggle.checkbox {
  min-height: 1.9rem !important;
}

.ui.modal {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: solid thin #ddd;
}

.ui.modal>.header {
  border-bottom: solid thin #ddd !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5);
}

.confirmCustom .actions {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

a:hover {
  color: #394EFF;
}