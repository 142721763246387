.dateRangePopup_wrapper_lQJk5MQ .dateRangePopup_body_2usNG-B {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: solid thin #ddd;
  padding: 5px;
}

.dateRangePopup_preSelections_3pkMrF- {
  width: 130px;
  background-color: white;
  border-right: solid thin #ddd
}

.dateRangePopup_preSelections_3pkMrF- > div {
  padding: 8px 10px;
  width: 100%;
  cursor: pointer;
}

.dateRangePopup_preSelections_3pkMrF- > div:hover {
  background-color: #F6F7FF;
}

