/* dark mode */

.dark_mode , .dark_mode .header ,.dark_mode #app ,.dark_mode .divider-light,.dark_mode .playerBlockHeader_header_1d_u7IO,
.dark_mode .player_playerBody_2JH3OUN, .dark_mode .EventsToggleButton_wrapper_1iyB6GU,.dark_mode .sharePopup_closeBtn_mUoKSQx,
.dark_mode .ui.bottom.popup:before{
  background-color: #101924 !important;
  color: #8094ae !important;
  border-color: #203247 !important;
}

.dark_mode #session-item {
  background: #101924;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.125);
}

.dark_mode span, .dark_mode .header a, .dark_mode .color-gray-darkest,.dark_mode .controls_skipIntervalButton_r0LMAhe,
.dark_mode .controlButton_controlButton_nqn_9eC .controlButton_label_3tzEdL7,.dark_mode .sharePopup_wrapper_RET9z1J,
.dark_mode .css-13cymwt-control:hover, .dark_mode .sharePopup_shareButton_2YLXl_4:hover, .dark_mode .css-19bb58m,
.dark_mode .css-1dimb5e-singleValue {
  color: #8094ae;
}

.dark_mode .sharePopup_closeBtn_mUoKSQx:after, .dark_mode .sharePopup_closeBtn_mUoKSQx:before{
  border-color: #808080;
}

.dark_mode .siteDropdown_wrapper {
  border-left: solid thin #203247 !important;
}

.dark_mode .header_divider, .dark_mode .accountPopup_dropdown_inner, .dark_mode .loadInfo_bottomBlock_3tUrJuP,
.dark_mode .header_userDetails a:hover,.dark_mode .header_userDetails button:hover,
.dark_mode #filter-item:hover, .dark_mode .dropdownPlain_dropdown_3SdcBrP:hover, .dark_mode .css-tr4s17-option{
  background: #101924;
}

.dark_mode .ui.checkbox .box:before, .ui.checkbox label:before{
  border: 1px solid #293797;
  background: #101924;
}

.dark_mode .bg-white .ui.input>input, .dark_mode .bg-white .ui.dropdown .menu {
  background: #101924 !important;
  border: 1px solid #101924;
}

.dark_mode .eventFilter_randomElement , .dark_mode .ui.input.focus>input,.dark_mode .ui.input>input:focus,
.dark_mode .filterModal_modal, .dark_mode .ui.input>input, .dark_mode .bg-white , .dark_mode .attributeItem_wrapper,
.dark_mode .eventEditor_wrapper, .sharePopup_shareButton_2YLXl_4:hover{
  background: #18212d !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #8094ae;
}

.dark_mode .event_clickType_25e2mZB, .dark_mode .event_inputType_1bdxVUh{
  border: none;
}

.dark_mode .bg-white .ui.input>input:focus{
  border: 1px solid #101924 !important;
  background: #101924;
}

.dark_mode .sessionMenu_divider{
  background-color: #203247;
}

.dark_mode .siteDropdown_wrapper ul li {
  border-bottom: 1px solid #203247;
}

.dark_mode .siteDropdown_wrapper ul li:first-child ,.dark_mode .header_userDetails li:first-child,
.dark_mode .controls_controls_1D0qMlX {
  border-top: 1px solid #203247;
}

.dark_mode .ui.icon.input>i.icon:not(.link) {
  color: #8094ae;
}

.dark_mode .siteDropdown_wrapper .menu ,.dark_mode .header_userDetails li,.dark_mode .sharePopup_closeBtn_mUoKSQx {
  background-color: #1b2633 !important;
  border: 1px solid #203247;
}

.dark_mode svg {
  fill: grey;
}

.dark_mode .accountPopup_SlideBtn {
  background-color: #1b2633;
  border: #203247 solid 2px;
}

.dark_mode .accountPopup_SlideBtn_knob {
  left: calc(2rem - 22px - 0.125rem);
  background-color: #203247;
}

.dark_mode .ui.input>input[type=search], .dark_mode .ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input,
.dark_mode .ui.basic.label, .dark_mode .ui.selection.dropdown, .dark_mode .linkStyledInput_input,
.dark_mode .ui.selection.dropdown .menu>.item ,.dark_mode .iconButton_button:hover,.dark_mode .popup:before,
.dark_mode .sortDropdown_dropdown:hover, .dark_mode .timezoneDropdown_dropdown:hover, .dark_mode .popup {
  background-color: #1b2633 !important;
  color: #8094ae !important;
  border-color: #203247 !important;
}

.dark_mode .filterSelectionButton_wrapper, .dark_mode .attributeItem_operatorDropdown,
.dark_mode .eventEditor_index {
  background-color: transparent !important;
  color: #8094ae !important;
  border: 1px solid #203247 !important;
}

.dark_mode .siteDropdown_wrapper:hover ,.dark_mode .siteDropdown_wrapper ul li:hover,.dark_mode .fill-gray-medium svg ,
.dark_mode .header_userDetails:hover,.dark_mode .bg-white .ui.dropdown .menu>.item:hover,.dark_mode .playerBlockHeader_divider_3cW-h6V,
.dark_mode .ui.dropdown>.left.menu, .dark_mode .bg-gray-light,.dark_mode .bg-gray-light-shade, .dark_mode .player_overlay_1ULgyXj,
.dark_mode .ui.toggle.customCheckbox input~label:after, .dark_mode .controls_divider_3l5lShv,
.dark_mode .controlButton_controlButton_nqn_9eC.controlButton_active_B8IO7Y5, .dark_mode .controlButton_controlButton_nqn_9eC:hover,
.dark_mode .controls_skipIntervalButton_r0LMAhe:hover, .dark_mode .sharePopup_shareButton_2YLXl_4:hover,
.dark_mode .css-d7l1ni-option:hover,
.dark_mode .ui.checkbox input:checked~.box:after, .dark_mode .ui.checkbox input:checked~label:after, .dark_mode .controls_speedButton_2hPU-Em:hover,
.dark_mode .ui.checkbox label:hover, .ui.checkbox+label:hover, .dark_mode .ui.checkbox .box:hover::before, 
.dark_mode .ui.checkbox label:hover::before, .dark_mode .css-1p3m7a8-multiValue, .dark_mode .Toastify__toast,
.dark_mode .timelinePointer_wrapper_2OHqJiT .timelinePointer_pin_3pkeI4L{
  background-color: transparent !important;
}

.dark_mode .eventFilter_searchField i.icon:not(.link) {
  z-index: 111;
}

.noContent_icon_3MD5Bif {
  opacity: 0.6;
  -webkit-filter: invert(.8);
          filter: invert(.8);
}

.dark_mode .bg-gray-light-shade .bg-white ,.dark_mode .widgetHOC_panel_38nMF3D, .dark_mode .eventGroupWrapper_container_1DWjETX,
.dark_mode .eventGroupWrapper_last_IQ1We_n, .dark_mode .event_event_om9ssmo.event_highlighted_pqFopVp,
.dark_mode .event_event_om9ssmo .event_topBlock_3Q0Eq_k{
  background: #18212d !important;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4) !important;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4) !important;
  border: none;
}

.dark_mode .filters_divider ,.dark_mode .filters_divider_P2adX3Z{
  margin: 10px 0;
  border-top: solid thin #203247;
}

.dark_mode .filters_pdf_icon:before {
  -webkit-filter: invert(.7);
          filter: invert(.7);
}

.dark_mode .border-t {
  border-color: #203247;
}

.dark_mode .timeline_timeline_3WeeHez {
  background-color: #798ba4;
}

.dark_mode .ui.bottom.right.popup {
  background: #101924;
  border-color: #101924;
}

.dark_mode .sharePopup_shareButton_2YLXl_4{
  -webkit-box-shadow: 0px 1px 3px 0 #101924 !important;
          box-shadow: 0px 1px 3px 0 #101924 !important;
}

.dark_mode .css-13cymwt-control, .dark_mode .sharePopup_body_2-88e39 textarea {
  background: #18212d !important;
  border: 1px solid #203247;
}

.dark_mode .css-t3ipsp-control {
  background-color: transparent !important;
}

.dark_mode .popup:before {
  -webkit-box-shadow: -1px -1px 0 0 #1b2633;
          box-shadow: -1px -1px 0 0 #1b2633;
}

.dark_mode .sharePopup_link_blBW4_S, .dark_mode .sharePopup_wrapper_RET9z1J, .dark_mode .css-d7l1ni-option,
.dark_mode .css-1nmdiq5-menu {
  background-color: #1b2633;
}