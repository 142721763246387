.AassistActions_inCall_1WjLkw9 svg {
  fill: #cc0000;
}

.AassistActions_inCall_1WjLkw9 {
  color: #cc0000;
}

.AassistActions_disabled_JBSkH60 {
  opacity: 0.5;
  pointer-events: none;
}