.header_header_27gvRlR {
  position: fixed;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: solid thin #ddd;
  padding: 0 15px;
  background: #fff;
  z-index: 100;
}

.header_nav_1rhxP6w {
  position: relative;
  margin: 0 15px;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
}

.header_nav_1rhxP6w:hover, .header_nav_1rhxP6w.header_active_3_n3a5x {
  color: #394EFF;
  border-bottom: 2px solid #394EFF;
}

.header_nav_1rhxP6w {
  position: relative;
}

.header_logo_11R6T0C {
  background-image: url("/img/logos/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 50px;
  width: 30px;
  margin-right: 15px;
}

.header_right_2hKYOiL {
  margin-left: auto;
  position: relative;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.header_right_2hKYOiL > .header_menuItem_3rqFZLd {
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: solid thin #ddd;
  position: relative;
}

.header_right_2hKYOiL > .header_menuItem_3rqFZLd .header_dropdown_3pK23xL {
  position: absolute;
  right: 0;
  top: 50px;
}

.header_userDetails_2892wji {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
  padding: 0 5px 0 15px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  min-width: 100px
}

.header_userDetails_2892wji:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header_userDetails_2892wji:hover ul {
  display: block;
}

.header_userDetails_2892wji ul {
  display: none;
  position: absolute;
  list-style: none;
  right: -15px;
  top: 50px;
  left: -1px;
  background: #fff;
  z-index: 1;
}

.header_userDetails_2892wji li {
  border: 1px solid #ddd;
  border-top: none;
}

.header_userDetails_2892wji li:first-child {
  border-top: 1px solid #ddd;
}

.header_userDetails_2892wji a, .header_userDetails_2892wji button {
  color: #333;
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  font-size: 14px;
}

.header_userDetails_2892wji a:hover, .header_userDetails_2892wji button:hover {
  background-color: #f6f6f6;
}

.header_userIcon_3Ara-7r {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-person' viewBox='0 0 16 16' fill='%23666'%3E %3Cpath d='M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.header_headerIcon_3RJsjHi {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 15px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.header_headerIcon_3RJsjHi:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header_headerIcon_3RJsjHi[data-active=true] {
  background-color: #f6f6f6;
}

.header_divider_2XPAvqw {
  width: 1px;
  background-color: #ddd;
}

.header_placeOnTop_3WnDWER {
  z-index: 9999;
}

.header_newBadge_1zfdXT5 {
  position: absolute;
  height: 14px;
  width: 28px;
  background-image: linear-gradient(40deg, #6051FF 0%, #FF693B 100%);
  border-radius: 3px;
  font-size: 9px;
  line-height: 15px;
  color: white;
  text-align: center;
  right: -22px;
  top: 10px;
}