.filters_dropdown_1bQporF {
  display: 'flex' !important;
  -webkit-box-align: 'center';
      -ms-flex-align: 'center';
          align-items: 'center';
  padding: 5px 8px;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500
}

.filters_dropdown_1bQporF:hover {
  background-color: #DDDDDD;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.filters_dateInput_3l6aLsJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #333
}

.filters_dateInput_3l6aLsJ:hover {
  background-color: lightgray;
  border-radius: 3px;
}

.filters_divider_P2adX3Z {
  margin: 10px 0;
  border-top: solid thin #ddd;
}

.filters_pdf_icon_3fPXlkF{
  position: absolute;
  right: 5px;
  display: inline;
}